import { theme as defaultTheme } from '@chakra-ui/core';

const EssentialInsuranceTheme = {
  ...defaultTheme,
  colors: {
    ...defaultTheme.colors,
    brandGreen: '#8dc63f',
    brandGreenHover: '#83b83b',
    brandOrange: '#FE9900',
    brandOrangeHover: '#E89416',
    bodyGray: '#f7f9fc',
  },
};

export default EssentialInsuranceTheme;
