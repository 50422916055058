import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Text,
  Input,
  InputGroup,
  Box,
  Spinner,
  Flex,
  Alert,
  AlertIcon,
} from "@chakra-ui/core";
import axios from "axios";
import { navigate } from "gatsby";

import { getFromLocalStorage } from "../../utils/functions";
import Button from "../partials/Button";

export default function Callback({ isOpen, toggle }) {
  const [data, setData] = useState({
    id: "",
    firstName: "",
    lastName: "",
    phone: "",
    partnerFirstName: "",
    partnerLastName: "",
    inputDisabled: true,
    formLoading: false,
    formSuccess: false,
    formErrors: false,
    modalOpen: true,
    errorMessage: "",
    errorSubMessage: "",
    buttonDisabled: ''
  });

  useEffect(() => {
    const userDetails = getFromLocalStorage("userDetails");
    const questions = getFromLocalStorage("questions");

    if (userDetails && questions) {
      setData({
        id: userDetails["id"],
        firstName: userDetails["firstName"],
        lastName: userDetails["lastName"],
        phone: "",
        partnerFirstName: userDetails["partnerFirstName"]
          ? userDetails["partnerFirstName"]
          : "",
        partnerLastName: userDetails["partnerLastName"]
          ? userDetails["partnerLastName"]
          : "",
        formLoading: false,
        formSuccess: false,
        formErrors: false,
        questions,
        buttonDisabled: false
      });
    }
  }, []);

  const handelChange = ({ target }) => {
    setData({
      ...data,
      phone: target.value,
    });
  };

  const handleSubmit = () => {
    setData({
      ...data,
      buttonDisabled: true
    })

    let internationaltelephonevalidation = new data8.internationaltelephonevalidation();

    internationaltelephonevalidation.isvalid(
      data.phone,
      "GB",
      [
        new data8.option("UseMobileValidation", "false"),
        new data8.option("UseLineValidation", "false"),
        new data8.option("RequiredCountry", ""),
        new data8.option("AllowedPrefixes", ""),
        new data8.option("BarredPrefixes", ""),
        new data8.option("UseUnavailableStatus", "true"),
        new data8.option("UseAmbiguousStatus", "true"),
        new data8.option("TreatUnavailableMobileAsInvalid", "false"),
        new data8.option("ExcludeUnlikelyNumbers", "false"),
      ],
      showIsValidResult
    );

    async function showIsValidResult(result) {
      if (!result.Status.Success) {
        setData({
          ...data,
          loading: false,
          formErrors: true,
          formSubmitted: true,
          errorMessage: "Ooops",
          errorSubMessage:
            'Something went wrong, your request has not been submitted, please call me on <a style="font-weight: bold;" href="tel:01625466384" fontWeight="bold">&nbsp;01625 466384</a>',
        });
        return;
      } else if (result.Result.ValidationResult === "Invalid") {
        setData({
          ...data,
          formErrors: true,
          errorMessage: "Please provide a valid phone number",
        });
      } else {
        const emailSubject = `${data.firstName} ${data.lastName} ${data.id} CALL BACK REQUEST ${data.phone} SOURCE - ONLINE FACT FIND`;
        const emailHeading = `Essential Online Fact Find Callback Request`;

        await axios
          .post(
            `${process.env.GATSBY_SUBMIT_API_ENDPOINT}`,
            {
              id: data.id,
              firstName: data.firstName,
              lastName: data.lastName,
              phone: data.phone,
              partnerFirstName: data.partnerFirstName,
              partnerLastName: data.partnerLastName,
              questions: data.questions,
              emailSubject: emailSubject,
              emailHeading: emailHeading,
              emailType: 0
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then(() => {
            navigate("/success");
          })
          .catch(() => {
            setData({
              ...data,
              loading: false,
              formErrors: true,
              formSubmitted: true,
              errorMessage: "Ooops",
              errorSubMessage:
                'Something went wrong, your request has not been submitted, please call me on <a style="font-weight: bold;" href="tel:01625466384" fontWeight="bold">&nbsp;01625 466384</a>',
              serverError: true,
            });
          });
      }
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={toggle}
        closeOnOverlayClick={false}
        isCentered
      >
        <ModalOverlay />
        <ModalContent rounded="md">
          <Box
            as="form"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <ModalHeader bg="gray.100" roundedTop="md">
              <Text>Request a callback</Text>
            </ModalHeader>

            <ModalBody>
              {data.formLoading && (
                <Flex alignItems="center" justifyContent="center" mt={4}>
                  <Spinner size="lg" />
                </Flex>
              )}

              {data.formErrors && (
                <ModalErros
                  errors={data.formErrors}
                  mt={4}
                  errorMessage={data.errorMessage}
                  errorSubMessage={data.errorSubMessage}
                />
              )}

              {!data.formSubmitted && (
                <>
                  <Text as="p" mt={4} mb={4}>
                    Please confirm the number you’d like me to call you on and
                    I’ll be in touch as soon as possible.
                  </Text>

                  <InputGroup>
                    <Input
                      type="number"
                      onChange={handelChange}
                      isRequired
                      isRequired
                    />
                  </InputGroup>
                </>
              )}
            </ModalBody>

            <ModalFooter mt={8} borderTop="1px" borderTopColor="gray.100">
              <Button
                onClick={toggle}
                bg={data.formSuccess ? "red.400" : "gray.100"}
                color={data.formSuccess ? "white" : "gray.600"}
              >
                Close
              </Button>
              {data.formSuccess === false && (
                <Box ml={3}>
                  <Button
                    type="submit"
                    bg="brandGreen"
                    bgHover="brandGreenHover"
                    disabled={data.buttonDisabled}
                    waka="red"
                  >
                    Call me
                  </Button>
                </Box>
              )}
            </ModalFooter>
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
}

export function ModalErros({ mt, errorMessage, errorSubMessage }) {
  return (
    <Flex direction="column">
      <Alert status="error" variant="left-accent" mt={mt}>
        <AlertIcon />
        {errorMessage}
      </Alert>
      {errorSubMessage && (
        <Flex mt={4}>
          <Text dangerouslySetInnerHTML={{ __html: errorSubMessage }} />
        </Flex>
      )}
    </Flex>
  );
}