import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { Flex, Button, Text, Image, Box, Icon } from '@chakra-ui/core';

import CallBack from './CallBack';

export default function Header() {
  const [data, setData] = useState({
    modalOpen: false,
  });

  const previousStep = () => {
    navigate(-1);
  };

  const toggleModal = (e) => {
    e.preventDefault();
    setData({
      modalOpen: !data.modalOpen,
    });
  };

  return (
    <>
      <CallBack isOpen={data.modalOpen} toggle={toggleModal} />
      <Flex direction="column" justifyContent="center" mb={{ base: 4, lg: 6 }}>
        <Flex px={4} py={2} bg="gray.100" justifyContent="center">
          <Flex justifyContent="flex-end" width={{ base: '100%', lg: 1024 }}>
            <Flex
              as="span"
              bg="transparent"
              justifyContent="center"
              alignItems="center"
              fontSize={{ base: 14, md: 16 }}
            >
              <Text as="span">Contact me:</Text>
              <Text as="a" href="tel:01625466384" fontWeight="bold">
                <Text as="span">&nbsp;01625 466384</Text>
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex bg="white" justifyContent="center" px={4}>
          <Flex
            as="header"
            py={{ xs: 4, md: 8 }}
            justifyContent="space-between"
            alignSelf="center"
            width={{ base: '100%', lg: '1024px' }}
            direction={{ base: 'column', lg: 'row' }}
          >
            <Flex alignItems={{ base: 'center' }} mb={{ xs: 2, lg: 0 }}>
              <Box w={{ base: '250px', lg: '250px' }}>
                <Image
                  src={'/img/Essential-insurance-logo.svg'}
                  alt="Essential insurance logo"
                />
              </Box>
              <Box ml={4} overflow="hidden">
                <Text
                  as="a"
                  href="https://www.feefo.com/reviews/essential-insurance"
                  target="_blank"
                >
                  <Image
                    src="https://api.feefo.com/api/logo?merchantidentifier=essential-insurance"
                    alt="Feefo logo"
                    title="See what our customers say about us"
                    transform="scale(1.07) translateY(-0.5px)"
                  />
                </Text>
              </Box>
            </Flex>
            <Flex>
              <Flex
                justifyContent={{ base: 'center', lg: 'flex-end' }}
                alignItems="center"
                mb={{ xs: 2, lg: 0 }}
              >
                <Button
                  onClick={toggleModal}
                  bg="brandOrange"
                  fontSize={{ base: 14, md: 16 }}
                  _hover={{ bg: 'brandOrangeHover' }}
                  _active={{ bg: 'brandOrangeHover' }}
                  color="white"
                >
                  Request a callback
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
