import React from "react";
import { ThemeProvider, CSSReset, Flex } from "@chakra-ui/core";
import { Helmet } from "react-helmet";

import EssentialInsuranceTheme from "../../essential-insurance/Theme";
import Header from "../partials/Header";
import Footer from "../partials/Footer";

//utils
import {
  getFromLocalStorage,
  capitalizeFirstLetter,
} from "../../utils/functions";

let title = "Essential Insurance";

export default function Layout({ children }) {

  // const { props } = children;
  // const { question } = props;

  // if (question) {
  //   title = question.emailQuestion
  // }

  return (
    <ThemeProvider theme={EssentialInsuranceTheme}>
      <CSSReset />
      <Flex
        direction="column"
        minHeight="100vh"
        overflow="scroll"
        bg="#fafafa"
        color="#000000"
      >
        <Helmet>
          <title>{title}</title>
          <script
            type="text/javascript"
            src={`https://webservices.data-8.co.uk/Javascript/Loader.ashx?key=${process.env.GATSBY_PHONE_VALIDATION_KEY}&load=InternationalTelephoneValidation`}
          ></script>
        </Helmet>
        <Header />
        <Flex as="main" p={4} mb="6" justifyContent="center">
          <Flex width={{ base: "100%", lg: 1024 }}>{children}</Flex>
        </Flex>
        <Footer />
      </Flex>
    </ThemeProvider>
  );
}
