import React from 'react';
import { Button as ChakraButton } from '@chakra-ui/core';

export default function Button({
  children,
  type,
  bg,
  bgHover,
  mt,
  onClick,
  color = `#fff`,
  disabled,
  waka
}) {

  return (
    <>
      <ChakraButton
        type={type}
        mt={mt}
        bg={bg}
        _hover={{ bg: bgHover }}
        _active={{ bg: bgHover }}
        onClick={onClick}
        color={color ? color : 'white'}
        disabled={disabled}
      >
        {children}
      </ChakraButton>
    </>
  );
}
