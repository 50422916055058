import React from 'react';
import { Flex, Text } from '@chakra-ui/core';

export default function Footer() {
  return (
    <Flex
      p={4}
      mt={8}
      as="footer"
      justifyContent="center"
      bg="gray.700"
      marginTop="auto"
    >
      <Flex
        width={{ base: '100%', lg: 1024 }}
        fontSize="12px"
        direction="column"
        color="white"
      >
        <Text>
          Essential Insurance Services is a trading style of Essential Finance
          Group (UK) Limited an appointed representative of Essential Finance
          Group Management Limited authorised and regulated by the Financial
          Conduct Authority No. 782710. Essential Finance Group (UK) Limited is
          registered in England and Wales, No. 9357808.
        </Text>
        <Text mt={4}>
          Essential Insurance © 2020  
          <Text
            as="a"
            href="https://www.essentialinsurance.co.uk/privacy-policy/"
            target="_blank"
            textDecoration="underline"
          >
            <b>Privacy Policy</b>
          </Text>
        </Text>
      </Flex>
    </Flex>
  );
}
